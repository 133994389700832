import { Button, Checkbox, Col, Modal, Row, Steps, Typography } from "antd";
import { useEffect, useMemo, useState } from "react";
import { dracula, CopyBlock } from "react-code-blocks";
import { RotatingLines } from "react-loader-spinner";
import { useHistory } from "react-router-dom";
import DeleteTypeModal from "../../../Components/LockrDeleteModal";
import { TitleText } from "../../../Components/NewAppModal";
import SideDrawer from "../../../Components/SideDrawer";
import {
  dataEndpointFullRefresh,
  deleteAWSEndpoint,
  getS3EndPoints,
  newS3DataEndPoint,
  updateS3DataEndPoint,
} from "../../../Utils/ApiActions";
import {
  DATA_ENDPOINTS,
  mapSnowflakStatusToUI,
  mapSnowflakeStatusToColor,
} from "../../../Utils/Helper";
import {
  DataEndpointTitleInput,
  DataEndpointTitleValue,
  DataEndpointTypeDropDown,
  FrequencyDropDown,
  FullRefreshNoticeModal,
} from "./AWSDataEndpoint";
import { FullRefreshLoader } from "./components/full-refresh-loader";
import { AppSelectionDropdown } from "./components/AppSelectionDropdown";

const { Step } = Steps;

export function SnowflakeDataEndPoint() {
  const defaultConnectionDetails = {
    externalId: "",
    roleARN: "",
    frequency: "Daily",
    type: "Incremental",
    name: "",
    uid2Enabled: false,
    id5Enabled: false,
    liverampEnabled: false,
    firstPartyCookies: "",
    externalUserArn: "",
    externalId: "",
    appIDs:[],
    domains:[],
    sqsQueueArn: "",
    isNotificationEventCreated: false,
    isTrustPolicyUpdated: false,
    dateCreatedEnabled: false,
  };

  const [displayLoading, setDisplayLoading] = useState(false);
  const [awsConnections, setAWSConnections] = useState([]);
  const [showNewConnection, setShowNewConnection] = useState(false);
  const [showFullRefreshModal, setShowFullRefreshModal] = useState(undefined);
  const [formMode, setFormMode] = useState("NEW");
  const [displayFullRefreshLoading, setDisplayFullRefreshLoading] =
    useState(false);
  const [showFullRefreshIndicator, setShowFullRefreshIndicator] =
    useState(false);
  const [selectedConnectionToDelete, setSelectedConnectionToDelete] =
    useState(undefined);

  const [newConnectionDetails, setNewConnectionDetails] = useState(
    defaultConnectionDetails
  );

  const [selectedConnectionDetails, setSelectedConnectionDetails] =
    useState(undefined);

  const [step, setStep] = useState(0);

  const history = useHistory();

  const selectedConnectionConfiguration = useMemo(() => {
    try {
      return JSON.parse(selectedConnectionDetails.configuration);
    } catch (error) {
      console.log("Unable to set the configurations");
      console.log(error);
    }
  }, [selectedConnectionDetails]);

  useEffect(() => {
    getS3EndpointsCall();
  }, []);

  async function startFullRefresh() {
    try {
      setShowFullRefreshIndicator(true);
      setShowFullRefreshModal(false);
      setDisplayFullRefreshLoading(true);
      selectedConnectionDetails?.id &&
        (await dataEndpointFullRefresh(selectedConnectionDetails.id));
      getS3EndpointsCall();
    } catch (error) {
      console.log("Unable to do the full refresh at the moment");
      console.log(error);
    }
    setTimeout(() => {
      setDisplayFullRefreshLoading(false);
    }, 10000);
  }

  async function updateS3EndPointCall(reqBody, id) {
    setDisplayLoading(true);
    try {
      const data = await updateS3DataEndPoint(reqBody, id, DATA_ENDPOINTS.SNOWFLAKE);
      setShowNewConnection(false);
      getS3EndpointsCall(true);
      data.success && setStep(step == 4 ? 4 : step + 1);
    } catch (error) {
      console.log("Unable to update the endpoint details at the moment");
      console.log(error);
    }
    setDisplayLoading(false);
  }

  async function newS3DataEndPointCall(reqBody) {
    setDisplayLoading(true);
    try {
      const { data } = await newS3DataEndPoint(
        reqBody,
        DATA_ENDPOINTS.SNOWFLAKE
      );
      setSelectedConnectionDetails(data);
      getS3EndpointsCall();
      setShowNewConnection(false);
      setStep(2);
    } catch (error) {
      console.log("Unable to create new data endpoint at the moment");
      console.log(error);
    }
    setDisplayLoading(false);
  }

  async function getS3EndpointsCall(dontChangeApp = false) {
    try {
      setDisplayLoading(true);
      const data = await getS3EndPoints(DATA_ENDPOINTS.SNOWFLAKE);
      if (data.success) {
        let endpointData = !dontChangeApp
          ? data?.data?.[0]
          : data?.data?.find(
              (item) => item.id === selectedConnectionDetails?.id
            );
        setAWSConnections(data.data);
        setSelectedConnectionDetails(endpointData);
        console.log(
          "dataaaaa",
          data?.data?.[0],
          JSON.parse(data?.data?.[0]?.configuration)
        );
        setNewConnectionDetails((prev) => ({
          ...prev,
          ...JSON.parse(endpointData?.configuration),
        }));
        step == 0 && setStep(getStep(JSON.parse(endpointData?.configuration)));
        setFormMode("EDIT");
      }
    } catch (error) {
      console.log("Unable to get the S3 endpoints");
      console.log(error);
    }
    setDisplayLoading(false);
  }
  console.log("step", step);
  async function deleteAWSEndpointCall() {
    try {
      await deleteAWSEndpoint(selectedConnectionToDelete);
      setSelectedConnectionToDelete(undefined);
      getS3EndpointsCall();
    } catch (error) {
      console.log("Unable to delete the endpoint at th eommoent");
      console.log(error);
    }
  }

  function renderEmptySection() {
    if (!displayLoading && awsConnections.length <= 0 && !showNewConnection) {
      return (
        <div
          style={{
            marginLeft: 90,
            marginRight: 90,
            minHeight: "50vh",
            background: "black",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img alt="logo_grey" src={require("../../../assets/logo_grey.png")} />
          <div
            style={{
              marginTop: 42,
              flexDirection: "column",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography.Text
              style={{
                fontFamily: "Montserrat",
                fontStyle: "normal",
                fontWeight: "700",
                fontSize: "24px",
                color: "#fff",
              }}
            >
              Connect lockr with Snowflake
            </Typography.Text>
            <Button
              style={{ marginTop: 24, width: "217px" }}
              type="primary"
              className="btn-first-connection"
              onClick={() => {
                setFormMode("NEW");
                setStep(1);
                setNewConnectionDetails(defaultConnectionDetails);
                setShowNewConnection(true);
              }}
            >
              Add New Connection
            </Button>
          </div>
        </div>
      );
    }
  }

  function checkNewFormValidation() {
    let validation = true;
    console.log(
      "formMode",
      formMode,
      newConnectionDetails?.isTrustPolicyUpdated,
      newConnectionDetails?.isNotificationEventCreated,
      newConnectionDetails?.externalId,
      newConnectionDetails?.externalUserArn,
      newConnectionDetails?.sqsQueueArn
    );
    if (
      newConnectionDetails?.name?.trim() === "" ||
      (step == 2 &&
        (newConnectionDetails?.externalId?.trim() === "" ||
          newConnectionDetails?.externalUserArn?.trim() === "")) ||
      (step == 3 &&
        !newConnectionDetails?.isNotificationEventCreated &&
        newConnectionDetails?.sqsQueueArn?.trim() === "")
    ) {
      validation = false;
    }
    return validation;
  }

  function onSubmitButtonClick() {
    const details = { ...newConnectionDetails, step };
    if (formMode === "EDIT") {
      updateS3EndPointCall(details, selectedConnectionDetails.id);
      setNewConnectionDetails(details);
      return;
    }
    newS3DataEndPointCall(details);
    setFormMode("EDIT");
    setNewConnectionDetails(details);
  }

  function getStep(data) {
    if (data?.isTrustPolicyUpdated && data?.isNotificationEventCreated) {
      return 4;
    } else if (
      data?.isTrustPolicyUpdated &&
      !data?.isNotificationEventCreated
    ) {
      return 3;
    } else if (
      !data?.isTrustPolicyUpdated &&
      !data?.isNotificationEventCreated
    ) {
      return 2;
    }
    return 1;
  }

  function renderNewDataEndpointForm() {
    return (
      <div
        style={{
          background: "black",
          flex: 1,
          display: "flex",
          flexDirection: "column",
          paddingLeft: 20,
          paddingTop: 24,
          paddingBottom: 24,
          paddingRight: 20,
        }}
      >
       
       <div style={{display: 'flex', justifyContent: 'center'}}>
        <div style={{minWidth: "70%"}}>
          <Steps current={step} className="custom-steps-dark">
            <Step
              status={step > 1 ? "finish" : "process"}
              onClick={()=> setStep(1)}
            />
            <Step
              status={step > 2 ? "finish" : step === 2 ? "process" : "wait"}
              onClick={()=> newConnectionDetails?.roleARN && setStep(2)}
            />
            <Step
              status={step === 3 ? "process" : "wait"} // Active when on step 3
              onClick={()=> newConnectionDetails?.isTrustPolicyUpdated && setStep(3)}
            />
          </Steps>
        </div>
       </div>
        
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <TitleText
            value={newConnectionDetails?.name}
            onChange={(event) => {
              setNewConnectionDetails({
                ...newConnectionDetails,
                name: event.target.value,
              });
            }}
            placeholder="Connection Name"
          />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: 8,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              style={{ width: "200px" }}
              type="primary"
              size="small"
              disabled={step == 1}
              className="sign-in dataendpoint-submit-button"
              onClick={() => {
                setStep(step - 1);
              }}
            >
              Previous
            </Button>
            <Button
              style={{ width: "200px" }}
              type="primary"
              size="small"
              disabled={!checkNewFormValidation()}
              className="sign-in dataendpoint-submit-button"
              onClick={() => {
                onSubmitButtonClick();
              }}
            >
              {step == 3 ? "Save" : "Next"}
            </Button>
            {formMode == "NEW" ||
            (selectedConnectionConfiguration?.isNotificationEventCreated &&
              selectedConnectionConfiguration?.isTrustPolicyUpdated) ? (
              <Button
                style={{ width: "200px" }}
                type="primary"
                size="small"
                className="sign-in dataendpoint-submit-button"
                onClick={() => {
                  setShowNewConnection(false);
                  setStep(getStep(selectedConnectionConfiguration));
                  setNewConnectionDetails({
                    ...defaultConnectionDetails,
                    ...selectedConnectionConfiguration,
                  });
                  setFormMode("EDIT");
                }}
              >
                Cancel
              </Button>
            ) : null}
            <div>
              <img
                alt="delete"
                onClick={() => {
                  setSelectedConnectionToDelete(selectedConnectionDetails.id);
                }}
                src={require("../../../assets/remove_icon.png")}
                style={{
                  marginLeft: 16,
                  marginRight: 16,
                  cursor: "pointer",
                  width: 18,
                  height: 20,
                  marginTop: -3,
                }}
              />
            </div>
          </div>
        </div>
        {step !== 1 && (
          <div
            style={{
              paddingTop: 24,
              paddingLeft: 16,
              paddingRight: 16,
              paddingBottom: 24,
              border: "1px solid #353738",
              width: "100%",
              marginTop: 20,
            }}
          >
            <span
              style={{
                color: "white",
                fontSize: 14,
                fontWeight: "500",
                fontFamily: "Montserrat",
              }}
            >
              Connection Settings
            </span>

            {step == 2 ? (
              <>
                {trustPolicyUpdateSteps()}
                <div
                  style={{
                    marginTop: 20,
                  }}
                >
                  <DataEndpointTitleInput
                    title={"STORAGE_AWS_IAM_USER_ARN *"}
                    value={newConnectionDetails.externalUserArn ?? ""}
                    onChange={(event) => {
                      setNewConnectionDetails({
                        ...newConnectionDetails,
                        externalUserArn: event.target.value,
                      });
                    }}
                  />
                </div>

                <div
                  style={{
                    marginTop: 20,
                  }}
                >
                  <DataEndpointTitleInput
                    title={"STORAGE_AWS_EXTERNAL_ID *"}
                    value={newConnectionDetails.externalId ?? ""}
                    onChange={(event) => {
                      setNewConnectionDetails({
                        ...newConnectionDetails,
                        externalId: event.target.value,
                      });
                    }}
                  />
                </div>
              </>
            ) : step == 3 ? (
              <>
                {createNotificationEventSteps()}

                <div
                  style={{
                    marginTop: 20,
                  }}
                >
                  <DataEndpointTitleInput
                    title={"notification_channel *"}
                    value={newConnectionDetails.sqsQueueArn ?? ""}
                    onChange={(event) => {
                      setNewConnectionDetails({
                        ...newConnectionDetails,
                        sqsQueueArn: event.target.value,
                      });
                    }}
                  />
                </div>
              </>
            ) : step == 4 ? (
              <div>
                <div
                  style={{
                    marginTop: 20,
                  }}
                >
                  <DataEndpointTitleValue
                    title={"STORAGE_AWS_IAM_USER_ARN"}
                    value={newConnectionDetails?.externalUserArn ?? ""}
                  />
                </div>

                <div
                  style={{
                    marginTop: 20,
                  }}
                >
                  <DataEndpointTitleValue
                    title={"STORAGE_AWS_EXTERNAL_ID"}
                    value={newConnectionDetails?.externalId ?? ""}
                  />
                </div>
                <div
                  style={{
                    marginTop: 20,
                  }}
                >
                  <DataEndpointTitleValue
                    title={"notification_channel"}
                    value={newConnectionDetails?.sqsQueueArn ?? ""}
                  />
                </div>
              </div>
            ) : (
              <div></div>
            )}
          </div>
        )}
        {formMode == "NEW" || step == 1 ? (
          <>
            <div
              style={{
                paddingTop: 24,
                paddingLeft: 16,
                paddingRight: 16,
                paddingBottom: 24,
                border: "1px solid #353738",
                width: "100%",
                marginTop: 20,
              }}
            >
              <span
                style={{
                  color: "white",
                  fontSize: 14,
                  fontWeight: "500",
                  fontFamily: "Montserrat",
                }}
              >
                Delivery Settings
              </span>
              <div style={{ marginTop: 20 }}>
                <DataEndpointTitleInput
                  title={"Connection Type"}
                  customInput={
                    <DataEndpointTypeDropDown
                      value={newConnectionDetails.type}
                      onChange={(event) => {
                        setNewConnectionDetails({
                          ...newConnectionDetails,
                          type: event,
                          frequency:
                            newConnectionDetails.frequency === "Hourly" &&
                            event === "Complete Dump"
                              ? "Daily"
                              : newConnectionDetails.frequency,
                        });
                      }}
                    />
                  }
                />
              </div>
              <div style={{ marginTop: 20 }}>
                <DataEndpointTitleInput
                  title={"Frequency"}
                  customInput={
                    <FrequencyDropDown
                      hideYearly
                      hideHourly={newConnectionDetails.type === "Complete Dump"}
                      value={newConnectionDetails.frequency}
                      onChange={(event) => {
                        setNewConnectionDetails({
                          ...newConnectionDetails,
                          frequency: event,
                        });
                      }}
                    />
                  }
                />
              </div>
            </div>

            <div
              style={{
                paddingTop: 24,
                paddingLeft: 16,
                paddingRight: 16,
                paddingBottom: 24,
                border: "1px solid #353738",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                marginTop: 20,
              }}
            >
              <AppSelectionDropdown
                value={newConnectionDetails?.appIDs}
                onChange={(appIDs) => {
                  setNewConnectionDetails((prevState) => ({
                    ...prevState,
                    appIDs,
                  }));
                }}
                domainsValue={newConnectionDetails?.domains}
                onDomainChange={(domains) => {
                  setNewConnectionDetails((prevState) => ({
                    ...prevState,
                    domains,
                  }));
                }}
              />
            </div>
            <div
              style={{
                paddingTop: 24,
                paddingLeft: 16,
                paddingRight: 16,
                paddingBottom: 24,
                border: "1px solid #353738",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                marginTop: 20,
              }}
            >
              <span
                style={{
                  color: "white",
                  fontSize: 14,
                  fontWeight: "500",
                  fontFamily: "Montserrat",
                }}
              >
                Attributes
              </span>
              <span
                style={{
                  marginTop: 16,
                  fontFamily: "Montserrat",
                  fontWeight: "500",
                  fontSize: 12,
                  color: "#8B8B8B",
                }}
              >
                Select the attributes to include for this connection with the
                endpoint.
              </span>
              <div
                style={{
                  marginTop: 32,
                  paddingLeft: 32,
                }}
              >
                <Checkbox
                  checked={newConnectionDetails?.firstName}
                  onChange={(event) => {
                    setNewConnectionDetails({
                      ...newConnectionDetails,
                      firstName: event.target.checked,
                    });
                  }}
                />
                <span
                  style={{
                    color: "white",
                    fontFamily: "Montserrat",
                    marginLeft: 12,
                    fontSize: 16,
                    fontWeight: "500",
                  }}
                >
                  First Name
                </span>
              </div>
              <div
                style={{
                  marginTop: 20,
                  paddingLeft: 32,
                }}
              >
                <Checkbox
                  checked={newConnectionDetails?.lastName}
                  onChange={(event) => {
                    setNewConnectionDetails({
                      ...newConnectionDetails,
                      lastName: event.target.checked,
                    });
                  }}
                />
                <span
                  style={{
                    color: "white",
                    fontFamily: "Montserrat",
                    marginLeft: 12,
                    fontSize: 16,
                    fontWeight: "500",
                  }}
                >
                  Last Name
                </span>
              </div>
              <div
                style={{
                  marginTop: 20,
                  paddingLeft: 32,
                }}
              >
                <Checkbox
                  checked={newConnectionDetails?.firstPartyCookies}
                  onChange={(event) => {
                    setNewConnectionDetails({
                      ...newConnectionDetails,
                      firstPartyCookies: event.target.checked,
                    });
                  }}
                />
                <span
                  style={{
                    color: "white",
                    fontFamily: "Montserrat",
                    marginLeft: 12,
                    fontSize: 16,
                    fontWeight: "500",
                  }}
                >
                  First Party Cookies
                </span>
              </div>
              <div
                style={{
                  marginTop: 20,
                  paddingLeft: 32,
                }}
              >
                <Checkbox
                  checked={newConnectionDetails?.rawEmail}
                  onChange={(event) => {
                    setNewConnectionDetails({
                      ...newConnectionDetails,
                      rawEmail: event.target.checked,
                    });
                  }}
                />
                <span
                  style={{
                    color: "white",
                    fontFamily: "Montserrat",
                    marginLeft: 12,
                    fontSize: 16,
                    fontWeight: "500",
                  }}
                >
                  Raw Email
                </span>
              </div>
              <div
                style={{
                  marginTop: 20,
                  paddingLeft: 32,
                }}
              >
                <Checkbox
                  checked={newConnectionDetails?.sha256}
                  onChange={(event) => {
                    setNewConnectionDetails({
                      ...newConnectionDetails,
                      sha256: event.target.checked,
                    });
                  }}
                />
                <span
                  style={{
                    color: "white",
                    fontFamily: "Montserrat",
                    marginLeft: 12,
                    fontSize: 16,
                    fontWeight: "500",
                  }}
                >
                  SHA256
                </span>
              </div>
              <div
                style={{
                  marginTop: 20,
                  paddingLeft: 32,
                }}
              >
                <Checkbox
                  checked={newConnectionDetails?.uid2Enabled}
                  onChange={(event) => {
                    setNewConnectionDetails({
                      ...newConnectionDetails,
                      uid2Enabled: event.target.checked,
                    });
                  }}
                />
                <span
                  style={{
                    color: "white",
                    fontFamily: "Montserrat",
                    marginLeft: 12,
                    fontSize: 16,
                    fontWeight: "500",
                  }}
                >
                  UID2 (latest)
                </span>
              </div>
              <div
                style={{
                  marginTop: 20,
                  paddingLeft: 32,
                }}
              >
                <Checkbox
                  checked={newConnectionDetails?.uid2EnabledHistory}
                  onChange={(event) => {
                    setNewConnectionDetails({
                      ...newConnectionDetails,
                      uid2EnabledHistory: event.target.checked,
                    });
                  }}
                />
                <span
                  style={{
                    color: "white",
                    fontFamily: "Montserrat",
                    marginLeft: 12,
                    fontSize: 16,
                    fontWeight: "500",
                  }}
                >
                  UID2 (history)
                </span>
              </div>
              <div
                style={{
                  marginTop: 20,
                  paddingLeft: 32,
                }}
              >
                <Checkbox
                  checked={newConnectionDetails?.id5Enabled}
                  onChange={(event) => {
                    setNewConnectionDetails({
                      ...newConnectionDetails,
                      id5Enabled: event.target.checked,
                    });
                  }}
                />
                <span
                  style={{
                    color: "white",
                    fontFamily: "Montserrat",
                    marginLeft: 12,
                    fontSize: 16,
                    fontWeight: "500",
                  }}
                >
                  ID5 (latest)
                </span>
              </div>
              <div
                style={{
                  marginTop: 20,
                  paddingLeft: 32,
                }}
              >
                <Checkbox
                  checked={newConnectionDetails?.id5EnabledHistory}
                  onChange={(event) => {
                    setNewConnectionDetails({
                      ...newConnectionDetails,
                      id5EnabledHistory: event.target.checked,
                    });
                  }}
                />
                <span
                  style={{
                    color: "white",
                    fontFamily: "Montserrat",
                    marginLeft: 12,
                    fontSize: 16,
                    fontWeight: "500",
                  }}
                >
                  ID5 (history)
                </span>
              </div>

              <div
                style={{
                  marginTop: 20,
                  paddingLeft: 32,
                }}
              >
                <Checkbox
                  checked={newConnectionDetails?.liverampEnabled}
                  onChange={(event) => {
                    setNewConnectionDetails({
                      ...newConnectionDetails,
                      liverampEnabled: event.target.checked,
                    });
                  }}
                />
                <span
                  style={{
                    color: "white",
                    fontFamily: "Montserrat",
                    marginLeft: 12,
                    fontSize: 16,
                    fontWeight: "500",
                  }}
                >
                  ATS (latest)
                </span>
              </div>
              <div
                style={{
                  marginTop: 20,
                  paddingLeft: 32,
                }}
              >
                <Checkbox
                  checked={newConnectionDetails?.liverampEnabledHistory}
                  onChange={(event) => {
                    setNewConnectionDetails({
                      ...newConnectionDetails,
                      liverampEnabledHistory: event.target.checked,
                    });
                  }}
                />
                <span
                  style={{
                    color: "white",
                    fontFamily: "Montserrat",
                    marginLeft: 12,
                    fontSize: 16,
                    fontWeight: "500",
                  }}
                >
                  ATS (history)
                </span>
              </div>
              <div
                style={{
                  marginTop: 20,
                  paddingLeft: 32,
                }}
              >
                <Checkbox
                  checked={newConnectionDetails?.successEvent}
                  onChange={(event) => {
                    setNewConnectionDetails({
                      ...newConnectionDetails,
                      successEvent: event.target.checked,
                    });
                  }}
                />
                <span
                  style={{
                    color: "white",
                    fontFamily: "Montserrat",
                    marginLeft: 12,
                    fontSize: 16,
                    fontWeight: "500",
                  }}
                >
                  Authentication Method
                </span>
              </div>

              <div
                style={{
                  marginTop: 20,
                  paddingLeft: 32,
                }}
              >
                <Checkbox
                  checked={newConnectionDetails?.consentString}
                  onChange={(event) => {
                    setNewConnectionDetails({
                      ...newConnectionDetails,
                      consentString: event.target.checked,
                    });
                  }}
                />
                <span
                  style={{
                    color: "white",
                    fontFamily: "Montserrat",
                    marginLeft: 12,
                    fontSize: 16,
                    fontWeight: "500",
                  }}
                >
                  TCF String
                </span>
              </div>

              <div
                style={{
                  marginTop: 20,
                  paddingLeft: 32,
                }}
              >
                <Checkbox
                  checked={newConnectionDetails?.dateCreatedEnabled}
                  onChange={(event) => {
                    setNewConnectionDetails({
                      ...newConnectionDetails,
                      dateCreatedEnabled: event.target.checked,
                    });
                  }}
                />
                <span
                  style={{
                    color: "white",
                    fontFamily: "Montserrat",
                    marginLeft: 12,
                    fontSize: 16,
                    fontWeight: "500",
                  }}
                >
                  Created At
                </span>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
        <div style={{ marginTop: 20 }}>
          <Button
            style={{ width: "200px" }}
            type="primary"
            size="small"
            className="sign-in"
            disabled={!checkNewFormValidation()}
            onClick={() => {
              onSubmitButtonClick();
            }}
          >
            {step == 3 ? "Save" : "Next"}
          </Button>
        </div>
      </div>
    );
  }

  function trustPolicyUpdateSteps() {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 12,
            marginTop: 4,
          }}
        >
          <span
            style={{
              color: "white",
              fontSize: 14,
              fontWeight: "500",
              fontFamily: "Montserrat",
            }}
          >
            Run the following command in a Snowflake worksheet.
          </span>
          <CopyBlock
            language={"sql"}
            text={`
            CREATE DATABASE LOCKR;

            CREATE SCHEMA DATAENDPOINT;

            CREATE TABLE IF NOT EXISTS UID_TOKENS_DATA_${selectedConnectionDetails?.id} (
                first_name STRING,
                last_name STRING,
                token STRING,
                integration_type STRING,
                created STRING,
                email STRING,
                email_hash STRING,
                consent_string STRING,
                success_event STRING,
                first_party_cookies STRING
            );

            CREATE TABLE IF NOT EXISTS PUBLISHER_CONSENT_DATA_${selectedConnectionDetails?.id} (
                firstName STRING,
                lastName STRING,
                email STRING,
                consentGiven STRING
            );

            CREATE TABLE IF NOT EXISTS UID_TOKENS_HISTORY_DATA_${selectedConnectionDetails?.id} (
                first_name STRING,
                last_name STRING,
                token STRING,
                integration_type STRING,
                created STRING,
                email STRING,
                email_hash STRING,
                consent_string STRING,
                success_event STRING,
                first_party_cookies STRING
            );

            CREATE OR REPLACE FILE FORMAT LOCKR_DATAENDPOINT_FILE_FORMAT
                TYPE = CSV
                RECORD_DELIMITER = '\\n'
                FIELD_DELIMITER = ','
                SKIP_HEADER = 1
                NULL_IF = ('NULL', 'null', '')
                EMPTY_FIELD_AS_NULL = TRUE
                FIELD_OPTIONALLY_ENCLOSED_BY = '0x22'
                ERROR_ON_COLUMN_COUNT_MISMATCH = FALSE
                ENCODING = 'ISO885915';

            CREATE OR REPLACE STORAGE INTEGRATION LOCKR_DATAENDPOINT_${selectedConnectionDetails?.id}_INTEGRATION
                TYPE = EXTERNAL_STAGE
                STORAGE_PROVIDER = 'S3'
                ENABLED = TRUE
                STORAGE_AWS_ROLE_ARN = '${selectedConnectionConfiguration?.roleARN}'
                STORAGE_ALLOWED_LOCATIONS = ('s3://identity-publisher-bucket-${selectedConnectionDetails?.publisherID}/DataEndPoints/Snowflake/${selectedConnectionDetails?.id}/');

            DESC INTEGRATION LOCKR_DATAENDPOINT_${selectedConnectionDetails?.id}_INTEGRATION;
              `}
            theme={dracula}
            codeBlock
            showLineNumbers={false}
          />
        </div>

        <div
          style={{
            marginTop: 10,
          }}
        >
          <span
            style={{
              color: "white",
              fontSize: 14,
              fontWeight: "500",
              fontFamily: "Montserrat",
            }}
          >
            Update trust policy with the values:
          </span>
        </div>
      </div>
    );
  }

  function createNotificationEventSteps() {
    return (
      <div>
        <div style={{ display: "flex", flexDirection: "column", gap: 8 }}>
          <span
            style={{
              color: "white",
              fontSize: 14,
              fontWeight: "500",
              fontFamily: "Montserrat",
            }}
          >
            Run the following commands in a Snowflake worksheet:
          </span>
          <CopyBlock
            language={"sql"}
            text={`
            CREATE OR REPLACE STAGE  LOCKR_DATAENDPOINT_${selectedConnectionDetails?.id}_UID_TOKENS_STAGE
                  URL = 's3://identity-publisher-bucket-${selectedConnectionDetails?.publisherID}/DataEndPoints/Snowflake/${selectedConnectionDetails?.id}/uid_tokens/'
                  STORAGE_INTEGRATION = LOCKR_DATAENDPOINT_${selectedConnectionDetails?.id}_INTEGRATION;
              
            CREATE OR REPLACE STAGE  LOCKR_DATAENDPOINT_${selectedConnectionDetails?.id}_PUBLISHER_CONSENT_STAGE
                  URL = 's3://identity-publisher-bucket-${selectedConnectionDetails?.publisherID}/DataEndPoints/Snowflake/${selectedConnectionDetails?.id}/publisher_consent/'
                  STORAGE_INTEGRATION = LOCKR_DATAENDPOINT_${selectedConnectionDetails?.id}_INTEGRATION;
              
            CREATE OR REPLACE STAGE  LOCKR_DATAENDPOINT_${selectedConnectionDetails?.id}_UID_TOKENS_HISTORY_STAGE
                  URL = 's3://identity-publisher-bucket-${selectedConnectionDetails?.publisherID}/DataEndPoints/Snowflake/${selectedConnectionDetails?.id}/uid_tokens_history/'
                  STORAGE_INTEGRATION = LOCKR_DATAENDPOINT_${selectedConnectionDetails?.id}_INTEGRATION;

            CREATE OR REPLACE PIPE LOCKR_DATAENDPOINT_${selectedConnectionDetails?.id}_UID_TOKENS_PIPE
                  AUTO_INGEST=TRUE
                  As
                  COPY INTO LOCKR.DATAENDPOINT.UID_TOKENS_DATA_${selectedConnectionDetails?.id}
                  FROM @LOCKR_DATAENDPOINT_${selectedConnectionDetails?.id}_UID_TOKENS_STAGE
                  FILE_FORMAT=LOCKR_DATAENDPOINT_FILE_FORMAT;

            CREATE OR REPLACE PIPE LOCKR_DATAENDPOINT_${selectedConnectionDetails?.id}_PUBLISHER_CONSENT_PIPE
                  AUTO_INGEST=TRUE
                  As
                  COPY INTO LOCKR.DATAENDPOINT.PUBLISHER_CONSENT_DATA_${selectedConnectionDetails?.id}
                  FROM @LOCKR_DATAENDPOINT_${selectedConnectionDetails?.id}_PUBLISHER_CONSENT_STAGE
                  FILE_FORMAT=LOCKR_DATAENDPOINT_FILE_FORMAT;

            CREATE OR REPLACE PIPE LOCKR_DATAENDPOINT_${selectedConnectionDetails?.id}_UID_TOKENS_HISTORY_PIPE
                  AUTO_INGEST=TRUE
                  As
                  COPY INTO LOCKR.DATAENDPOINT.UID_TOKENS_HISTORY_DATA_${selectedConnectionDetails?.id}
                  FROM @LOCKR_DATAENDPOINT_${selectedConnectionDetails?.id}_UID_TOKENS_HISTORY_STAGE
                  FILE_FORMAT=LOCKR_DATAENDPOINT_FILE_FORMAT;

            SHOW PIPES;
              `}
            theme={dracula}
            codeBlock
            showLineNumbers={false}
          />
        </div>

        <div
          style={{
            marginTop: 10,
          }}
        >
          <span
            style={{
              color: "white",
              fontSize: 14,
              fontWeight: "500",
              fontFamily: "Montserrat",
            }}
          >
            Create a notification event with the sqsArnQueue generated from the above:
          </span>
        </div>
      </div>
    );
  }

  function renderMainSections() {
    if (awsConnections.length || showNewConnection) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            paddingLeft: 90,
            paddingRight: 90,
            position: "relative",
          }}
        >
          <div
            style={{
              maxWidth: 294,
              display: "flex",
              flex: 1,
              background: "#353738",
              flexDirection: "column",
              position: "relative",
            }}
          >
            <div
              style={{
                paddingBottom: 60,
              }}
            >
              {showNewConnection && formMode !== "EDIT" && (
                <div
                  style={{
                    paddingLeft: 20,
                    paddingRight: 20,
                    minHeight: 60,
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    position: "relative",
                    background: "black",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      position: "absolute",
                      cursor: "pointer",
                      left: 0,
                      height: "100%",
                      width: 4,
                      background: "#61CEF7",
                    }}
                  />
                  <span
                    style={{
                      fontFamily: "Montserrat",
                      fontSize: 18,
                      fontWeight: "500",
                      cursor: "pointer",
                      color: "#61CEF7",
                    }}
                  >
                    {newConnectionDetails.name ?? "New Connection"}
                  </span>
                </div>
              )}
              {awsConnections?.map?.((item) => {
                return (
                  <div
                    onClick={() => {
                      setShowNewConnection(false);
                      setSelectedConnectionDetails(item);
                      setStep(getStep(JSON.parse(item.configuration)));
                      setNewConnectionDetails((prev) => ({
                        ...prev,
                        ...JSON.parse(item.configuration),
                      }));
                    }}
                    style={{
                      paddingLeft: 20,
                      paddingRight: 20,
                      minHeight: 60,
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                      position: "relative",
                      background:
                        selectedConnectionDetails?.id === item?.id &&
                        (!showNewConnection ||
                          (showNewConnection && formMode === "EDIT"))
                          ? "black"
                          : "#353738",
                      width: "100%",
                    }}
                  >
                    {selectedConnectionDetails?.id === item?.id &&
                      (!showNewConnection ||
                        (showNewConnection && formMode === "EDIT")) && (
                        <div
                          style={{
                            position: "absolute",
                            left: 0,
                            height: "100%",
                            width: 4,
                            background: "#61CEF7",
                          }}
                        />
                      )}
                    <span
                      style={{
                        fontFamily: "Montserrat",
                        fontSize: 18,
                        fontWeight: "500",
                        color:
                          selectedConnectionDetails?.id === item.id &&
                          !showNewConnection
                            ? "#61CEF7"
                            : "white",
                      }}
                    >
                      {JSON.parse(item.configuration)?.name ?? "Connection"}
                    </span>
                  </div>
                );
              })}
            </div>
            <div
              style={{
                position: "absolute",
                bottom: 24,
                left: 24,
              }}
            >
              <Button
                style={{ width: "248px" }}
                type="primary"
                className="sign-in"
                onClick={() => {
                  setFormMode("NEW");
                  setStep(1);
                  setNewConnectionDetails(defaultConnectionDetails);
                  setShowNewConnection(true);
                }}
              >
                Add New Connection
              </Button>
            </div>
          </div>
          {step !== 4 ? (
            renderNewDataEndpointForm()
          ) : (
            <div
              style={{
                background: "black",
                flex: 1,
                display: "flex",
                flexDirection: "column",
                paddingLeft: 20,
                paddingTop: 24,
                paddingBottom: 24,
                paddingRight: 20,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <span
                  style={{
                    color: "white",
                    fontSize: 20,
                    fontFamily: "Montserrat",
                  }}
                >
                  {selectedConnectionConfiguration?.name ?? "Connection"}
                </span>
                <div
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    alignItems: "center",
                    gap: 8,
                  }}
                >
                  <Button
                    type={"primary"}
                    style={{ width: 200 }}
                    loading={false}
                    onClick={() => {
                      setFormMode("EDIT");
                      setShowNewConnection(true);
                      setStep(1);
                      setNewConnectionDetails({
                        ...selectedConnectionConfiguration,
                      });
                    }}
                    className="sign-in dataendpoint-submit-button"
                  >
                    {"Edit"}
                  </Button>
                  <Button
                    type={"primary"}
                    style={{ width: 200 }}
                    loading={false}
                    onClick={() => {
                      setFormMode("DUPLICATE");
                      setShowNewConnection(true);
                      setNewConnectionDetails({
                        ...selectedConnectionConfiguration,
                        isNotificationEventCreated: false,
                        isTrustPolicyUpdated: false,
                        sqsQueueArn: "",
                        externalId: "",
                        externalUserArn: "",
                        name: "",
                      });
                      setStep(1);
                    }}
                    className="sign-in dataendpoint-submit-button"
                  >
                    {"Duplicate"}
                  </Button>
                  <img
                    alt="delete"
                    onClick={() => {
                      setSelectedConnectionToDelete(
                        selectedConnectionDetails.id
                      );
                    }}
                    src={require("../../../assets/remove_icon.png")}
                    style={{
                      marginLeft: 16,
                      marginRight: 16,
                      cursor: "pointer",
                      width: 18,
                      height: 20,
                    }}
                  />
                </div>
              </div>
              <div
                style={{
                  paddingTop: 24,
                  paddingLeft: 16,
                  paddingRight: 16,
                  paddingBottom: 24,
                  border: "1px solid #353738",
                  width: "100%",
                  marginTop: 20,
                }}
              >
                <span
                  style={{
                    color: "white",
                    fontSize: 14,
                    fontWeight: "500",
                    fontFamily: "Montserrat",
                  }}
                >
                  Connection Settings
                </span>
                {newConnectionDetails &&
                newConnectionDetails.isTrustPolicyUpdated &&
                newConnectionDetails.isNotificationEventCreated ? (
                  <div>
                    <div
                      style={{
                        paddingTop: 24,
                        paddingLeft: 16,
                        paddingRight: 16,
                        paddingBottom: 24,
                        border: "1px solid #353738",
                        width: "100%",
                        marginTop: 20,
                      }}
                    >
                      {trustPolicyUpdateSteps()}
                      <div
                        style={{
                          marginTop: 20,
                        }}
                      >
                        <DataEndpointTitleValue
                          title={"STORAGE_AWS_IAM_USER_ARN"}
                          value={
                            selectedConnectionConfiguration?.externalUserArn ??
                            ""
                          }
                        />
                      </div>

                      <div
                        style={{
                          marginTop: 20,
                        }}
                      >
                        <DataEndpointTitleValue
                          title={"STORAGE_AWS_EXTERNAL_ID"}
                          value={
                            selectedConnectionConfiguration?.externalId ?? ""
                          }
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        paddingTop: 24,
                        paddingLeft: 16,
                        paddingRight: 16,
                        paddingBottom: 24,
                        border: "1px solid #353738",
                        width: "100%",
                        marginTop: 20,
                      }}
                    >
                      {createNotificationEventSteps()}
                      <div
                        style={{
                          marginTop: 20,
                        }}
                      >
                        <DataEndpointTitleValue
                          title={"notification_channel"}
                          value={
                            selectedConnectionConfiguration?.sqsQueueArn ?? ""
                          }
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        marginTop: 10,
                      }}
                    >
                      <span
                        style={{
                          color: "white",
                          fontSize: 14,
                          fontWeight: "500",
                          fontFamily: "Montserrat",
                          marginTop: 20,
                        }}
                      >
                        snowflake integrated sucessfully
                      </span>
                    </div>
                  </div>
                ) : (
                  <div>
                    <span
                      style={{
                        color: "white",
                        fontSize: 14,
                        fontWeight: "500",
                        fontFamily: "Montserrat",
                      }}
                    >
                      integrate
                    </span>
                  </div>
                )}

                <div
                  style={{
                    marginTop: 10,
                  }}
                >
                  <span
                    style={{
                      fontSize: 14,
                      color: "white",
                      fontWeight: "500",
                    }}
                  >
                    Status
                  </span>
                  <div
                    style={{
                      display: "flex",
                      marginTop: 8,
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        height: 10,
                        width: 10,
                        borderRadius: 20,
                        background: mapSnowflakeStatusToColor(
                          selectedConnectionDetails?.status
                        ),
                      }}
                    />
                    <span
                      className="connection-tile-font"
                      style={{
                        marginLeft: 8,
                        color: "white",
                        fontSize: 18,
                        fontFamily: "Montserrat",
                      }}
                    >
                      {mapSnowflakStatusToUI(selectedConnectionDetails?.status)}
                    </span>
                  </div>
                </div>
              </div>
              <div
                style={{
                  paddingTop: 24,
                  paddingLeft: 16,
                  paddingRight: 16,
                  paddingBottom: 24,
                  border: "1px solid #353738",
                  width: "100%",
                  marginTop: 20,
                }}
              >
                <span
                  style={{
                    color: "white",
                    fontSize: 14,
                    fontWeight: "500",
                    fontFamily: "Montserrat",
                  }}
                >
                  Delivery Settings
                </span>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-end",
                    marginTop: 20,
                    gap: 32,
                    position: "relative",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                    }}
                  >
                    <DataEndpointTitleValue
                      title={"Connection Type"}
                      showDropdown={true}
                      value={
                        selectedConnectionConfiguration?.type ===
                        "Complete Dump"
                          ? "Complete"
                          : selectedConnectionConfiguration?.type ?? ""
                      }
                    />
                  </div>
                  {selectedConnectionConfiguration?.type !==
                    "Complete Dump" && (
                    <Button
                      style={{ width: "200px" }}
                      type="primary"
                      size="small"
                      className="data-endpoint-refresh-button"
                      onClick={() => {
                        setShowFullRefreshModal(true);
                      }}
                    >
                      Full Refresh
                    </Button>
                  )}
                  {false &&
                    (displayFullRefreshLoading ||
                      selectedConnectionConfiguration?.status ===
                        "PROCESSING" ||
                      selectedConnectionConfiguration?.status ===
                        "IN_PROGRESS") && (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          height: 48,
                          gap: 8,
                          justifyContent: "center",
                        }}
                      >
                        <RotatingLines width="20" strokeColor="white" />
                        <label
                          style={{
                            color: "white",
                            marginTop: 12,
                            fontSize: 12,
                            fontWeight: "400",
                            fontFamily: "Montserrat",
                          }}
                        >
                          Transferring Data
                        </label>
                      </div>
                    )}
                </div>
                <div
                  style={{
                    marginTop: 20,
                  }}
                >
                  <DataEndpointTitleValue
                    title={"Freuency"}
                    showDropdown={true}
                    value={selectedConnectionConfiguration?.frequency ?? ""}
                  />
                </div>
              </div>

              <div
                style={{
                  paddingTop: 24,
                  paddingLeft: 16,
                  paddingRight: 16,
                  paddingBottom: 24,
                  border: "1px solid #353738",
                  width: "100%",
                  marginTop: 20,
                }}
              >
                <AppSelectionDropdown
                  disabled={true}
                  value={selectedConnectionConfiguration?.appIDs}
                  onChange={(appIDs) => {
                    setNewConnectionDetails((prevState) => ({
                      ...prevState,
                      appIDs,
                    }));
                  }}
                  domainsValue={selectedConnectionConfiguration?.domains}
                  onDomainChange={(domains) => {
                    setNewConnectionDetails((prevState) => ({
                      ...prevState,
                      domains,
                    }));
                  }}
                />
              </div>
              <div
                style={{
                  paddingTop: 24,
                  paddingLeft: 16,
                  paddingRight: 16,
                  paddingBottom: 24,
                  border: "1px solid #353738",
                  width: "100%",
                  marginTop: 20,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <span
                    style={{
                      color: "white",
                      fontSize: 14,
                      fontWeight: "500",
                      fontFamily: "Montserrat",
                    }}
                  >
                    Attributes
                  </span>
                  <span
                    style={{
                      marginTop: 16,
                      fontFamily: "Montserrat",
                      fontWeight: "500",
                      fontSize: 12,
                      color: "#8B8B8B",
                    }}
                  >
                    Select the attributes to include for this connection with
                    the endpoint.
                  </span>
                </div>
                <div
                  style={{
                    marginTop: 32,
                    paddingLeft: 32,
                  }}
                >
                  <Checkbox
                    checked={selectedConnectionConfiguration?.firstName}
                    disabled
                  />
                  <span
                    style={{
                      color: "white",
                      fontFamily: "Montserrat",
                      marginLeft: 12,
                      fontSize: 16,
                      fontWeight: "500",
                    }}
                  >
                    First Name
                  </span>
                </div>
                <div
                  style={{
                    marginTop: 20,
                    paddingLeft: 32,
                  }}
                >
                  <Checkbox
                    checked={selectedConnectionConfiguration?.lastName}
                    disabled
                  />
                  <span
                    style={{
                      color: "white",
                      fontFamily: "Montserrat",
                      marginLeft: 12,
                      fontSize: 16,
                      fontWeight: "500",
                    }}
                  >
                    Last Name
                  </span>
                </div>
                <div
                  style={{
                    marginTop: 20,
                    paddingLeft: 32,
                  }}
                >
                  <Checkbox
                    checked={selectedConnectionConfiguration?.firstPartyCookies}
                    disabled
                  />
                  <span
                    style={{
                      color: "white",
                      fontFamily: "Montserrat",
                      marginLeft: 12,
                      fontSize: 16,
                      fontWeight: "500",
                    }}
                  >
                    First Party Cookies
                  </span>
                </div>
                <div
                  style={{
                    marginTop: 20,
                    paddingLeft: 32,
                  }}
                >
                  <Checkbox
                    checked={selectedConnectionConfiguration?.rawEmail}
                    disabled
                  />
                  <span
                    style={{
                      color: "white",
                      fontFamily: "Montserrat",
                      marginLeft: 12,
                      fontSize: 16,
                      fontWeight: "500",
                    }}
                  >
                    Raw Email
                  </span>
                </div>
                <div
                  style={{
                    marginTop: 20,
                    paddingLeft: 32,
                  }}
                >
                  <Checkbox
                    checked={selectedConnectionConfiguration?.sha256}
                    disabled
                  />
                  <span
                    style={{
                      color: "white",
                      fontFamily: "Montserrat",
                      marginLeft: 12,
                      fontSize: 16,
                      fontWeight: "500",
                    }}
                  >
                    SHA256
                  </span>
                </div>
                <div
                  style={{
                    marginTop: 20,
                    paddingLeft: 32,
                  }}
                >
                  <Checkbox
                    checked={selectedConnectionConfiguration?.uid2Enabled}
                    disabled
                  />
                  <span
                    style={{
                      color: "white",
                      fontFamily: "Montserrat",
                      marginLeft: 12,
                      fontSize: 16,
                      fontWeight: "500",
                    }}
                  >
                    UID2 (latest)
                  </span>
                </div>
                <div
                  style={{
                    marginTop: 20,
                    paddingLeft: 32,
                  }}
                >
                  <Checkbox
                    checked={
                      selectedConnectionConfiguration?.uid2EnabledHistory
                    }
                    disabled
                  />
                  <span
                    style={{
                      color: "white",
                      fontFamily: "Montserrat",
                      marginLeft: 12,
                      fontSize: 16,
                      fontWeight: "500",
                    }}
                  >
                    UID2 (history)
                  </span>
                </div>
                <div
                  style={{
                    marginTop: 20,
                    paddingLeft: 32,
                  }}
                >
                  <Checkbox
                    checked={selectedConnectionConfiguration?.id5Enabled}
                    disabled
                  />
                  <span
                    style={{
                      color: "white",
                      fontFamily: "Montserrat",
                      marginLeft: 12,
                      fontSize: 16,
                      fontWeight: "500",
                    }}
                  >
                    ID5 (latest)
                  </span>
                </div>
                <div
                  style={{
                    marginTop: 20,
                    paddingLeft: 32,
                  }}
                >
                  <Checkbox
                    checked={selectedConnectionConfiguration?.id5EnabledHistory}
                    disabled
                  />
                  <span
                    style={{
                      color: "white",
                      fontFamily: "Montserrat",
                      marginLeft: 12,
                      fontSize: 16,
                      fontWeight: "500",
                    }}
                  >
                    ID5 (history)
                  </span>
                </div>

                <div
                  style={{
                    marginTop: 20,
                    paddingLeft: 32,
                  }}
                >
                  <Checkbox
                    checked={selectedConnectionConfiguration?.liverampEnabled}
                    disabled
                  />
                  <span
                    style={{
                      color: "white",
                      fontFamily: "Montserrat",
                      marginLeft: 12,
                      fontSize: 16,
                      fontWeight: "500",
                    }}
                  >
                    ATS (latest)
                  </span>
                </div>
                <div
                  style={{
                    marginTop: 20,
                    paddingLeft: 32,
                  }}
                >
                  <Checkbox
                    checked={
                      selectedConnectionConfiguration?.liverampEnabledHistory
                    }
                    disabled
                  />
                  <span
                    style={{
                      color: "white",
                      fontFamily: "Montserrat",
                      marginLeft: 12,
                      fontSize: 16,
                      fontWeight: "500",
                    }}
                  >
                    ATS (history)
                  </span>
                </div>
                <div
                  style={{
                    marginTop: 20,
                    paddingLeft: 32,
                  }}
                >
                  <Checkbox
                    checked={selectedConnectionConfiguration?.successEvent}
                    disabled
                  />
                  <span
                    style={{
                      color: "white",
                      fontFamily: "Montserrat",
                      marginLeft: 12,
                      fontSize: 16,
                      fontWeight: "500",
                    }}
                  >
                    Authentication Method
                  </span>
                </div>

                <div
                  style={{
                    marginTop: 20,
                    paddingLeft: 32,
                  }}
                >
                  <Checkbox
                    checked={selectedConnectionConfiguration?.consentString}
                    disabled
                  />
                  <span
                    style={{
                      color: "white",
                      fontFamily: "Montserrat",
                      marginLeft: 12,
                      fontSize: 16,
                      fontWeight: "500",
                    }}
                  >
                    TCF String
                  </span>
                </div>

                <div
                  style={{
                    marginTop: 20,
                    paddingLeft: 32,
                  }}
                >
                  <Checkbox
                    checked={
                      selectedConnectionConfiguration?.dateCreatedEnabled
                    }
                    disabled
                  />
                  <span
                    style={{
                      color: "white",
                      fontFamily: "Montserrat",
                      marginLeft: 12,
                      fontSize: 16,
                      fontWeight: "500",
                    }}
                  >
                    Created At
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
      );
    }
    return <></>;
  }

  return (
    <SideDrawer>
      <Row
        justify="space-between"
        align="middle"
        className="apiandservices-newapp-btn"
      >
        <Col sm={17} xs={24}>
          <Typography.Title className="apiandservices-heading">
            Snowflake Data Endpoint
          </Typography.Title>
          <div style={{ marginTop: 8 }} />
          <div style={{ display: "flex", flexDirection: "row" }}>
            <label
              style={{
                fontSize: 14,
                fontFamily: "Montserrat",
                color: "#8B8B8B",
              }}
              className="dashboard-as-of-today"
            >
              Use AIM's endpoint integration with Snowflake to deliver audience data via Snowpipes to your account in micro-batches.
            </label>
          </div>
        </Col>
        <Col sm={7} xs={24} className="createAppButton">
          <Button
            style={{ width: "248px" }}
            type="primary"
            className="sign-in"
            onClick={() => {
              setFormMode("NEW");
              setStep(1);
              setNewConnectionDetails(
                JSON.parse(JSON.stringify(defaultConnectionDetails))
              );
              setShowNewConnection(true);
            }}
          >
            Add New Connection
          </Button>
        </Col>
      </Row>
      {renderEmptySection()}
      {renderMainSections()}
      {!!selectedConnectionToDelete && (
        <DeleteTypeModal
          visible={!!selectedConnectionToDelete}
          onCancel={() => {
            setSelectedConnectionToDelete(undefined);
          }}
          title="Delete Connection"
          message={`Are you sure you want to delete this Snowflake connection? Doing so will disable all future deliveries to this bucket.`}
          handleCancel={() => {
            setSelectedConnectionToDelete(undefined);
          }}
          handleOk={deleteAWSEndpointCall}
        />
      )}
      <FullRefreshNoticeModal
        display={showFullRefreshModal}
        onCancel={() => {
          setShowFullRefreshModal(false);
        }}
        onOk={() => {
          startFullRefresh();
        }}
      />

      {showFullRefreshIndicator && (
        <FullRefreshLoader
          onClose={() => {
            setShowFullRefreshIndicator(false);
          }}
          dataConfigurationId={selectedConnectionDetails?.id}
        />
      )}
    </SideDrawer>
  );
}
