import { Button, Checkbox, ConfigProvider, DatePicker, Dropdown, Typography } from "antd";
import locale from "antd/lib/date-picker/locale/en_US";
import moment from "moment";
import React, { useMemo, useState } from "react";
import { AiOutlineCheck, AiOutlineDown } from "react-icons/ai";
import {
  CartesianGrid,
  Label,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { getGraphColor } from "../../../Utils/Helper";
import { LockrFromToDatePicker } from "./IdentityProviderStatsAuthStrip";
import { AppSelectionDropdown } from "./AppSelectionDropdown";

export function IdentityProviderGraphStrip({
  startDate,
  endDate,
  onChangeStartDate,
  onChangeEndDate,
  graphData,
  historyData,
  title,
  supportCustomDatePicker,
  supportComparePrevious,
  loginTrendsAppIDs,
  setLoginTrendsAppIDs
}) {
  const dateFormat = "dddd MM/DD";

  const customFormat = (value) => `${value.format(dateFormat)}`;

  const [showComparison, setShowComparison] = useState(false);

  const [displayCustomFilter, setDisplayCustomFilter] = useState(false);

  const [selectedLine, setSelectedLine] = useState([]);

  const userGraphData = useMemo(() => {
    if (graphData?.length) {
      graphData.forEach((graphItem) => {
        if (graphItem.eventType === null) {
          graphItem.eventType = "Other";
        }
      });
      const startDateMoment = moment(startDate.valueOf());
      const result = [];
      const eventTypes = [];
      graphData.forEach((item) => {
        if (!eventTypes.includes(item.eventType)) {
          eventTypes.push(item.eventType);
        }
      });
      while (startDateMoment.isSameOrBefore(endDate)) {
        const currentDateString = startDateMoment.format("YYYY-MM-DD");
        eventTypes.forEach((typeItem) => {
          const itemIndex = result.findIndex((resultItem) => {
            if (resultItem.date === currentDateString) {
              return true;
            }
            return false;
          });
          if (itemIndex >= 0) {
            result[itemIndex][typeItem] = 0;
          } else {
            result.push({
              date: currentDateString,
              [typeItem]: 0,
              Total: 0,
            });
          }
        });
        graphData.forEach((item) => {
          if (item.date === currentDateString) {
            const eventType = item.eventType;
            const itemIndex = result.findIndex((resultItem) => {
              if (resultItem.date === item.date) {
                return true;
              }
              return false;
            });

            if (itemIndex >= 0) {
              result[itemIndex][eventType] = parseInt(item.count);
              result[itemIndex]["Total"] += parseInt(item.count);
            } else {
              result.push({
                date: item.date,
                [eventType]: parseInt(item.count),
                Total: parseInt(item.count),
              });
            }
          }
        });
        startDateMoment.add(1, "day");
      }
      return {
        labels: [...eventTypes, "Total"],
        data: result,
      };
    }
    return {
      labels: [],
      data: [],
    };
  }, [graphData, startDate, endDate]);

  const userHistoryData = useMemo(() => {
    if (historyData?.length) {
      const startDateMoment = moment(startDate.valueOf());
      const result = [];
      const eventTypes = [];
      const daysDifference = moment(endDate).diff(startDate, "days");
      historyData.forEach((item) => {
        if (!eventTypes.includes(item.eventType)) {
          eventTypes.push(item.eventType);
        }
      });
      while (startDateMoment.isSameOrBefore(endDate)) {
        const currentDateString = startDateMoment.format("YYYY-MM-DD");
        eventTypes.forEach((typeItem) => {
          const itemIndex = result.findIndex((resultItem) => {
            if (resultItem.date === currentDateString) {
              return true;
            }
            return false;
          });
          if (itemIndex >= 0) {
            result[itemIndex][typeItem] = 0;
          } else {
            result.push({
              date: currentDateString,
              [typeItem]: 0,
              Total: 0,
            });
          }
        });
        historyData.forEach((item) => {
          const comparisonDate = moment(item.date, "YYYY-MM-DD")
            .add(daysDifference, "days")
            .format("YYYY-MM-DD");
          if (comparisonDate === currentDateString) {
            const eventType = item.eventType;
            const itemIndex = result.findIndex((resultItem) => {
              if (resultItem.date === comparisonDate) {
                return true;
              }
              return false;
            });

            if (itemIndex >= 0) {
              result[itemIndex][eventType] = parseInt(item.count);
              result[itemIndex]["Total"] += parseInt(item.count);
            } else {
              result.push({
                date: comparisonDate,
                [eventType]: parseInt(item.count),
                Total: parseInt(item.count),
              });
            }
          }
        });
        startDateMoment.add(1, "day");
      }
      return {
        labels: [...eventTypes, "Total"],
        data: result,
      };
    }
    return {
      labels: [],
      data: [],
    };
  }, [historyData, startDate, endDate]);

  const appendedData = useMemo(() => {
    if (showComparison) {
      const newLabels = [...userGraphData.labels];
      userHistoryData.labels.forEach(labelItem => {
        if (newLabels.indexOf(labelItem) < 0 && labelItem !== null) {
          newLabels.push(labelItem);
        }
      })
      const newData = [];
      userGraphData.data.forEach((dataItem, index) => {
        const obj = {
          date: dataItem.date,
        };
        newLabels.forEach(labelItem => {
          obj[labelItem] = dataItem[labelItem] ?? 0
          obj[`${labelItem}_history`] = userHistoryData.data[index][labelItem] ?? 0
        })
        newData.push(obj)
      })
      return { data: newData, labels: newLabels };
    }
    return { data: userGraphData.data, labels: userGraphData.labels };
  }, [userHistoryData, userGraphData, showComparison]);

  const menu = (
    <div className="identity-provider-options-dropdown">
      <div
        style={{
          marginTop: 10,
        }}
        onClick={() => {
          setSelectedLine([]);
          setDisplayCustomFilter(false);
        }}
      >
        <AiOutlineCheck
          style={{
            color: selectedLine.length > 0 ? "transparent" : "#61CEF7",
            marginRight: 8,
          }}
        />
        <span
          style={{
            color: "white",
            cursor: "pointer",
            fontFamily: "Montserrat",
            fontSize: 14,
          }}
        >
          All
        </span>
      </div>
      {appendedData.labels.map((item) => {
        return (
          <div
            style={{
              marginTop: 10,
            }}
            onClick={() => {
              if (!selectedLine.includes(item)) {
                setSelectedLine([...selectedLine, item]);
                setDisplayCustomFilter(false);
              } else {
                const selectedLines = [...selectedLine];
                selectedLines.splice(selectedLines.indexOf(item));
                setSelectedLine(selectedLines);
              }
            }}
          >
            <AiOutlineCheck
              style={{
                color: selectedLine.includes(item) ? "#61CEF7" : "transparent",
                marginRight: 8,
              }}
            />
            <span
              style={{
                color: selectedLine.includes(item) ? "#61cef7" : "white",
                cursor: "pointer",
                fontFamily: "Montserrat",
                fontSize: 14,
              }}
            >
              {item === "SSO" ? "SSO" : item === "total" ? "Total" : item}
            </span>
          </div>
        );
      })}
    </div>
  );

  function renderDateFilters() {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        {supportComparePrevious && <div style={{
          display: 'flex',
          alignItems: 'center',
          marginRight: 16,
          flexDirection: 'row',
          gap: 8,
        }}>
          <Checkbox
            onChange={event => {
              setShowComparison(event.target.checked);

            }}
          />
          <span style={{
            fontSize: 14,
            color: 'white',
            fontWeight: '400',
            fontFamily: 'Montserrat',
          }}>Compare previous duration</span>
        </div>}
        <AppSelectionDropdown value={loginTrendsAppIDs} onChange={(appIDs)=>setLoginTrendsAppIDs(appIDs)} />
        <div
          style={{
            paddingRight: 10,
          }}
        >
          <Dropdown
            visible={displayCustomFilter}
            onClick={() => {
              setDisplayCustomFilter(true);
            }}
            onVisibleChange={event => {
              setDisplayCustomFilter(event);
            }}
            destroyPopupOnHide={false}
            placement="bottomRight"
            trigger={["click"]}
            overlay={menu}
          >
            <Button
              className="custom-view-more-options"
              style={{
                height: "56px",
                marginRight: 8,
                display: "flex",
                flexDirection: "row",
              }}
              type="primary"
              shape="default"
            >
              <div>{selectedLine?.length > 0 ? "Custom" : "All"} </div>
              <div>
                <AiOutlineDown
                  style={{ marginLeft: 12, paddingTop: 4 }}
                ></AiOutlineDown>
              </div>
            </Button>
          </Dropdown>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <LockrFromToDatePicker
            addCustomDatePicker={supportCustomDatePicker}
            endDate={endDate}
            startDate={startDate}
            onChangeEndDate={onChangeEndDate}
            onChangeStartDate={onChangeStartDate}
          />
        </div>
      </div>
    );
  }

  function renderGraphLabels() {
    if (appendedData.labels.length) {
      return (
        <div
          style={{
            display: "flex",
            marginTop: 28,
            flexDirection: "row",
            alignItems: "center",
            gap: 24,
          }}
        >
          {appendedData.labels.map((item, index) => {
            return (
              <div
                style={{
                  flexDirection: "row",
                  display: "flex",
                  alignItems: "center",
                  gap: 8,
                }}
              >
                <div
                  style={{
                    height: 12,
                    width: 12,
                    borderWidth: 1,
                    borderRadius: 200,
                    background: getGraphColor(index),
                  }}
                />
                <span
                  style={{
                    color: "white",
                    fontSize: 16,
                    fontFamily: "Montserrat",
                  }}
                >
                  {item === "SSO" ? "SSO" : item === "total" ? "Total" : item}
                </span>
              </div>
            );
          })}
        </div>
      );
    }
    return <></>;
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <span
            style={{
              color: "white",
              fontSize: 24,
              fontWeight: "700",
              fontFamily: "Montserrat",
            }}
          >
            {title ?? 'Analytics'}
          </span>
        </div>
        {renderDateFilters()}
      </div>
      {renderGraphLabels()}
      <div
        style={{
          paddingTop: 30,
          paddingBottom: 30,
          background: "black",
          marginTop: 24,
          paddingRight: 30,
        }}
      >
        <ResponsiveContainer width={"100%"} height={320}>
          {appendedData.labels.length ? <LineChart
            width={500}
            height={300}
            data={[...appendedData.data]}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray={"3 3"} color="#353738" />
            <XAxis dataKey="date" />
            <YAxis>
              <Label
                dx={-18}
                style={{ fontFamily: "Montserrat", fontSize: 14 }}
                angle={270}
                fill="#8B8B8B"
                value={"Tokens Generated"}
              ></Label>
            </YAxis>
            <Tooltip
              contentStyle={{ background: "#171717", border: "0" }}
              labelStyle={{ display: "none" }}
              wrapperStyle={{ background: "black" }}
              content={({ active, payload, label }) => {
                console.log("🚀 ~ label:", payload)
                return <div className='connection-action-popover' style={{
                  minWidth: 220,
                  minHeight: 20,
                  background: '#171717',
                  display: 'flex',
                  flexDirection: 'column',
                  padding: 16,
                }}>
                  {
                    payload.map(item => {
                      return (
                        <div style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'space-between'
                        }}>
                          <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'row'
                          }}>
                            <div style={{
                              width: 12,
                              height: 12,
                              backgroundColor: item.color,
                              borderRadius: 200,
                            }} />
                            <span style={{
                              color: 'white',
                              marginLeft: 8
                            }}>{item.name.indexOf('_history') >= 0 ? item.name.replace('_history', '') + ' History' : item.name}</span>
                          </div>
                          <div>
                            <span style={{
                              color: 'white',
                              fontWeight: '700'
                            }}>{item.value}</span>
                          </div>
                        </div>
                      )
                    })
                  }
                </div>
              }}
            ></Tooltip>
            {!!appendedData.labels.length &&
              appendedData.labels.map((item, index) => {
                if (
                  (selectedLine.includes(item) || selectedLine?.length <= 0)) {
                  return (
                    <>
                      <Line
                        dot={{ display: "none" }}
                        activeDot={{ display: "none" }}
                        type="monotone"
                        dataKey={item}
                        stroke={getGraphColor(index)}
                        strokeWidth={2}
                      />
                      <Line
                        dot={{ display: "none" }}
                        activeDot={{ display: "none" }}
                        type="monotone"
                        dataKey={item + '_history'}
                        stroke={getGraphColor(index) + 'aa'}
                        strokeWidth={2}
                      />
                    </>
                  );
                }
              })}
          </LineChart> : 
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <span style={{ color: "white" }}>There is no data available for the selected date range.</span>
            </div>}
        </ResponsiveContainer>
      </div>
    </>
  );
}
