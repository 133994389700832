import React from "react";
import { Button, Modal } from 'antd';
import { IoMdArrowBack } from 'react-icons/io';
import { identitProvidersType } from "../Utils/Helper";
import {withRouter} from 'react-router-dom';

function UIDGreyLogo() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="129" height="19" viewBox="0 0 129 19" fill="none">
            <g clip-path="url(#clip0_7984_8692)">
                <path d="M9.18569 1.05859H13.6791V11.2988C13.6791 13.0394 13.3926 14.349 12.8005 15.254C11.6354 17.0401 9.66321 18.1032 7.00103 18.1032C5.52549 18.1032 4.26722 17.8326 3.24532 17.2676C1.17526 16.1136 0.177246 14.5525 0.177246 11.3203V1.05859H4.67072V11.2533C4.67072 13.3339 5.36074 14.349 6.95327 14.349C8.54581 14.349 9.18808 13.626 9.18808 11.2748V1.05859H9.18569Z" fill="#8B8B8B" />
                <path d="M24.9203 17.783V10.8205C24.9203 9.12541 24.302 8.33531 22.8527 8.33531C21.2601 8.33531 20.594 9.1709 20.594 10.866V17.783H16.6472V5.23716H20.3313V6.57075H20.3791C21.2099 5.28265 22.3751 4.80859 23.9677 4.80859C26.3457 4.80859 28.8646 6.07515 28.8646 9.78143V17.783H24.9179H24.9203Z" fill="#8B8B8B" />
                <path d="M35.3595 5.23828H31.4128V17.7841H35.3595V5.23828Z" fill="#8B8B8B" />
                <path d="M96.3676 5.23828H92.4209V17.7841H96.3676V5.23828Z" fill="#8B8B8B" />
                <path d="M45.9531 17.7868V8.09011H42.5531V17.7868H38.6064V8.09011H37.1333V5.24096H38.6064V4.63043C38.6064 2.12127 40.0104 0.742188 43.7183 0.742188H44.2412V3.56978H43.957C42.959 3.56978 42.5531 4.0223 42.5531 4.83634V5.24336H49.8974V17.7892H45.9507L45.9531 17.7868Z" fill="#8B8B8B" />
                <path d="M55.934 12.6545C56.1489 14.0791 57.4072 15.0272 59.0236 15.0272C60.0933 15.0272 60.7833 14.6417 61.4256 13.8516H65.4654C64.2048 16.5883 61.8052 18.1924 59.0236 18.1924C55.2202 18.1924 52.0352 15.2762 52.0352 11.546C52.0352 7.81576 55.0769 4.80859 58.952 4.80859C62.8271 4.80859 65.9167 7.77027 65.9167 11.6585C65.9167 12.02 65.8928 12.3145 65.8212 12.6521H55.9317L55.934 12.6545ZM61.9724 10.1238C61.6619 8.83571 60.4753 7.97617 58.9544 7.97617C57.529 7.97617 56.2922 8.79021 55.9842 10.1238H61.9747H61.9724Z" fill="#8B8B8B" />
                <path d="M78.0878 17.7848V16.4296H78.0401C77.3023 17.5812 75.9724 18.1918 74.0933 18.1918C70.0988 18.1918 67.4844 15.2756 67.4844 11.4783C67.4844 7.68106 70.1705 4.81037 74.0217 4.81037C75.5426 4.81037 76.7794 5.23894 77.8968 6.30198V1.05859H81.8435V17.7848H78.0878ZM78.1117 11.4544C78.1117 9.71377 76.7101 8.31314 74.7595 8.31314C72.8088 8.31314 71.4311 9.62519 71.4311 11.4544C71.4311 13.2836 72.8326 14.6866 74.7355 14.6866C76.6385 14.6866 78.1117 13.3075 78.1117 11.4544Z" fill="#8B8B8B" />
                <path d="M99.6431 17.7848V1.05859H105.633C107.82 1.05859 109.556 1.42012 110.793 2.07615C113.288 3.43129 114.857 6.00988 114.857 9.42167C114.857 14.938 111.433 17.7848 105.944 17.7848H99.6455H99.6431ZM106.157 14.033C108.747 14.033 110.363 12.4959 110.363 9.44322C110.363 6.39057 108.771 4.80797 106.181 4.80797H104.136V14.033H106.157Z" fill="#8B8B8B" />
                <path d="M96.5653 2.25524C96.5653 3.3949 95.5936 4.31908 94.3949 4.31908C93.1963 4.31908 92.2246 3.3949 92.2246 2.25524C92.2246 1.11558 93.1939 0.191406 94.3949 0.191406C95.5958 0.191406 96.5653 1.11558 96.5653 2.25524Z" fill="#8B8B8B" />
                <path d="M48.228 4.32147C47.0294 4.32147 46.0576 3.39729 46.0576 2.25764C46.0576 1.11798 47.0294 0.191406 48.228 0.191406C49.4266 0.191406 50.3983 1.11558 50.3983 2.25764C50.3983 3.39969 49.4266 4.32147 48.228 4.32147Z" fill="#8B8B8B" />
                <path d="M35.5571 2.25524C35.5571 3.3949 34.5853 4.31908 33.3867 4.31908C32.1881 4.31908 31.2163 3.3949 31.2163 2.25524C31.2163 1.11558 32.1857 0.191406 33.3843 0.191406C34.5829 0.191406 35.5547 1.11558 35.5547 2.25524" fill="#8B8B8B" />
                <path d="M116.895 17.5572V16.3792C117.662 15.9051 118.218 15.5053 118.564 15.1677C119.273 14.4854 119.66 13.8772 119.66 13.4199C119.66 13.0752 119.417 12.8429 119.07 12.8429C118.631 12.8429 118.388 13.1398 118.354 13.7815H116.835C116.835 12.3952 117.738 11.5117 119.128 11.5117C120.4 11.5117 121.253 12.297 121.253 13.2356C121.253 14.1741 120.705 15.0384 119.181 16.281H121.296V17.5548H116.895V17.5572Z" fill="#8B8B8B" />
                <path d="M127.113 13.7001C127.113 13.1781 126.886 12.8429 126.38 12.8429C125.907 12.8429 125.647 13.1949 125.647 13.7001V15.479C125.647 15.9913 125.907 16.3361 126.38 16.3361C126.886 16.3361 127.113 15.9985 127.113 15.479V13.7001ZM128.705 15.4478C128.705 16.8197 127.643 17.6697 126.404 17.6697C125.164 17.6697 124.052 16.8126 124.052 15.4478V13.7072C124.052 12.297 125.224 11.5117 126.387 11.5117C127.643 11.5117 128.705 12.3784 128.705 13.6929V15.4478Z" fill="#8B8B8B" />
                <path d="M123.751 16.9024C123.751 17.3525 123.366 17.7188 122.892 17.7188C122.416 17.7188 122.034 17.3525 122.034 16.9024C122.034 16.4523 122.419 16.0859 122.892 16.0859C123.364 16.0859 123.751 16.4499 123.751 16.9024Z" fill="#8B8B8B" />
            </g>
            <defs>
                <clipPath id="clip0_7984_8692">
                    <rect width="128.643" height="19" fill="white" transform="translate(0.177246)" />
                </clipPath>
            </defs>
        </svg>
    )
}

function UIDNoCredentialModalDetails({
    display, onOk, integrationType = identitProvidersType.UID, history
}) {

    function renderTitles() {
        return (
            <div style={{ marginTop: 50, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <span style={{ fontFamily: 'Montserrat', fontSize: 24, color: 'white', fontWeight: '700' }}>Connect lockr with {integrationType === identitProvidersType.UID ? 'UID2' : integrationType === identitProvidersType.ID5 ? 'ID5' : 'LiveRamp ATS'}</span>
                <Button
                    style={{ width: 217, marginTop: 24, cursor: 'pointer' }}
                    type="primary"
                    className="sign-in"
                    onClick={onOk}
                >Start Connection</Button>
                <div style = {{
                    position: 'absolute',
                    left: 16,
                    top: 16,
                }}>
                    <IoMdArrowBack onClick={()=>{
                        history.goBack();
                    }} style = {{
                        color: 'white',
                        fontSize: 24,
                        cursor: 'pointer'
                    }}/>
                </div>
            </div>
        )
    }

    return (
        <Modal visible={display}
            style={{
                top: 20, paddingBottom: 20,
            }}
            centered width={720} footer={null} closable={false} destroyOnClose
            bodyStyle={{ flexDirection: 'column', minHeight: 480, width: 720, backgroundColor: '#353738', paddingRight: 0, overflowX: 'hidden', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
            <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                <img
                    alt='logo_grey'
                    src={require('../assets/logo_grey.png')}
                />
                {integrationType === identitProvidersType.UID && <>
                    <span style={{ fontFamily: 'Montserrat', fontSize: 20, marginTop: 6, fontWeight: 300, color: '#8B8B8B' }}>+</span>
                    <div style={{ marginTop: 8 }}>
                        <UIDGreyLogo />
                    </div>
                </>}
                {renderTitles()}
            </div>
        </Modal>
    )
}

export const UIDNoCredentialModal = withRouter(UIDNoCredentialModalDetails);