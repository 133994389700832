/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  PieChart,
  Pie,
  Cell,
  XAxis,
  CartesianGrid,
  ResponsiveContainer,
  BarChart,
  Bar,
  Rectangle,
  YAxis,
  Tooltip,
  Legend,
} from "recharts";
import { getLockrMailPreferenceData } from "../../../Utils/ApiActions";
import { LockrFromToDatePicker } from "../../Connections/components/IdentityProviderStatsAuthStrip";
import { DATE_OPTIONS, IdentityProviderNonDatePicker } from "../../Connections/components/identityProviderNonDatePicker";

export function LockrMailUserPreferences() {
  const [lockrMailPreferenceData, setLockrMailPreferenceData] =
    useState(undefined);
  const [startDate, setStartDate] = useState(moment().subtract(7, "day"));
  const [endDate, setEndDate] = useState(moment());

  useEffect(() => {
    getLockrMailPreferenceDataCall();
  }, [startDate, endDate]);

  async function getLockrMailPreferenceDataCall() {
    try {
      const response = await getLockrMailPreferenceData(startDate, endDate);
      console.log("🚀 ~ getLockrMailPreferenceDataCall ~ response:", response)
      setLockrMailPreferenceData(response.data);
    } catch (error) {
      console.log("Unable to get the lockr mail preference data");
      console.log(error);
    }
  }

  function renderEmailSettingsChart() {
    const data = [
      { name: "Allow", value: lockrMailPreferenceData?.percentageEmailsAllowed ?? 0 },
      { name: "Block", value: lockrMailPreferenceData?.percentageEmailsBlocked ?? 0 },
      { name: "Filter", value: lockrMailPreferenceData?.percentageEmailsFiltered ?? 0 },
    ];
    return (
      <div
        style={{
          minHeight: 444,
          padding: 30,
          minWidth: 450,
          background: "black",
        }}
      >
        <span
          style={{
            color: "white",
            fontFamily: "Montserrat",
            fontWeight: "400",
            fontSize: 16,
          }}
        >
          Email Settings
        </span>
        <div>
          <span
            style={{
              fontFamily: "Montserrat",
              fontSize: 14,
              fontWeight: "400",
              color: "#8B8B8B",
            }}
          >
            Via {lockrMailPreferenceData?.domain ?? ''}
          </span>
        </div>
        {((lockrMailPreferenceData?.percentageEmailsAllowed?.toFixed(2) ?? 0) || (lockrMailPreferenceData?.percentageEmailsBlocked?.toFixed(2) ?? 0) || (lockrMailPreferenceData?.percentageEmailsFiltered?.toFixed(2) ?? 0)) ? <div
          style={{
            display: "flex",
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            paddingTop: 20
          }}
        >
          <PieChart width={320} height={320}>
            <Pie
              data={data}
              innerRadius={115}
              outerRadius={150}
              labelLine={false}
              cx="50%"
              cy="50%"
            >
              {data.map((item, index) => {
                return (
                  <Cell
                    stroke="transparent"
                    key={`cell-${index}`}
                    fill={
                      index === 0
                        ? "#50E8B1"
                        : index === 1
                          ? "#F46666"
                          : "#2E70D2"
                    }
                  />
                );
              })}
            </Pie>
          </PieChart>
          <div
            style={{
              position: "absolute",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  background: "#50E8B1",
                  borderRadius: 32,
                  height: 12,
                  width: 12,
                }}
              />
              <span
                style={{
                  color: "white",
                  fontFamily: "Montserrat",
                  fontWeight: "700",
                  fontSize: 16,
                  marginLeft: 8,
                }}
              >
                Allow
              </span>
              <span
                style={{
                  color: "white",
                  fontFamily: "Montserrat",
                  fontSize: 16,
                  fontWeight: "400",
                  marginLeft: 30,
                }}
              >
                {`${lockrMailPreferenceData?.percentageEmailsAllowed?.toFixed(2) ?? 0}%`}
              </span>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 12,
              }}
            >
              <div
                style={{
                  background: "#F46666",
                  borderRadius: 32,
                  height: 12,
                  width: 12,
                }}
              />
              <span
                style={{
                  color: "white",
                  fontFamily: "Montserrat",
                  fontWeight: "700",
                  fontSize: 16,
                  marginLeft: 8,
                }}
              >
                Block
              </span>
              <span
                style={{
                  color: "white",
                  fontFamily: "Montserrat",
                  fontSize: 16,
                  fontWeight: "400",
                  marginLeft: 30,
                }}
              >
                {`${lockrMailPreferenceData?.percentageEmailsBlocked?.toFixed(2) ?? 0}%`}
              </span>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 12,
              }}
            >
              <div
                style={{
                  background: "#2E70D2",
                  borderRadius: 32,
                  height: 12,
                  width: 12,
                }}
              />
              <span
                style={{
                  color: "white",
                  fontFamily: "Montserrat",
                  fontWeight: "700",
                  fontSize: 16,
                  marginLeft: 8,
                }}
              >
                Filter
              </span>
              <span
                style={{
                  color: "white",
                  fontFamily: "Montserrat",
                  fontSize: 16,
                  fontWeight: "400",
                  marginLeft: 30,
                }}
              >
                {`${lockrMailPreferenceData?.percentageEmailsFiltered?.toFixed(2) ?? 0}%`}
              </span>
            </div>
          </div>
        </div> :
          <div 
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <span style={{ color: "white" }}>There is no data available for the selected date range.</span>
          </div>
        }
      </div>
    );
  }

  function renderlockrMailPreferenceCards() {
    return (
      <div>
        <div
          style={{
            minWidth: 250,
            height: 100,
            background: "#171717",
            marginTop: 24,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <span
            style={{
              color: "#61CEF7",
              fontFamily: "Montserrat",
              fontWeight: "400",
              fontSize: 30,
            }}
          >
            {`${lockrMailPreferenceData?.openRate?.toFixed?.(2) ?? 0}%`}
          </span>
          <span
            style={{
              color: "#8B8B8B",
              fontWeight: "400",
              fontFamily: "Montserrat",
              fontSize: 12,
            }}
          >
            Open Rate
          </span>
        </div>
        <div
          style={{
            minWidth: 250,
            height: 100,
            background: "#171717",
            marginTop: 10,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <span
            style={{
              color: "#61CEF7",
              fontFamily: "Montserrat",
              fontWeight: "400",
              fontSize: 30,
            }}
          >
            {handleSafeParse(lockrMailPreferenceData?.totalEmailClicks)}
          </span>
          <span
            style={{
              color: "#8B8B8B",
              fontWeight: "400",
              fontFamily: "Montserrat",
              fontSize: 12,
            }}
          >
            Click Through
          </span>
        </div>
      </div>
    );
  }

  function handleSafeParse(value) {
    try {
      return parseInt(value)
    } catch (error) {
      return 0
    }
  }

  function renderEmailAnalyticsChart() {
    return (
      <div
        style={{
          minHeight: 444,
          padding: 30,
          background: "black",
          display: "flex",
          flex: 1,
          flexDirection: "column",
        }}
      >
        <span
          style={{
            color: "white",
            fontFamily: "Montserrat",
            fontWeight: "400",
            fontSize: 16,
          }}
        >
          Email Settings
        </span>
        <div style={{
            display:'flex',
            flexDirection: 'column',
            flex: 1,
            gap: '20px',
          }}
        >
          <span
            style={{
              fontFamily: "Montserrat",
              fontSize: 14,
              fontWeight: "400",
              color: "#8B8B8B",
            }}
          >
            Via {lockrMailPreferenceData?.domain ?? ''}
          </span>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flex: 1,
              gap: 60,
            }}
          >
            {(handleSafeParse(lockrMailPreferenceData?.totalEmailReceived ?? '0') || handleSafeParse(lockrMailPreferenceData?.totalDelivered ?? '0') || handleSafeParse(lockrMailPreferenceData?.totalEmailsopened ?? '0')) ? <><div
              style={{
                display: "flex",
                flex: 1,
              }}
            >
              <ResponsiveContainer>
                <BarChart
                  width={900}
                  height={344}
                  data={[
                    {
                      name: "",
                      received: handleSafeParse(lockrMailPreferenceData?.totalEmailReceived ?? '0'),
                      delivered: handleSafeParse(lockrMailPreferenceData?.totalDelivered ?? '0'),
                      open: handleSafeParse(lockrMailPreferenceData?.totalEmailsopened ?? '0'),
                    },
                  ]}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                  barGap={40}
                >
                  <CartesianGrid />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Bar
                    barSize={80}
                    dataKey="received"
                    fill="#61CEF7"
                    radius={[60, 60, 0, 0]}
                  />
                  <Bar
                    barSize={80}
                    dataKey="delivered"
                    fill="#FAC748"
                    radius={[60, 60, 0, 0]}
                  />
                  <Bar
                    barSize={80}
                    dataKey="open"
                    fill="#BD94BC"
                    radius={[60, 60, 0, 0]}
                  />
                </BarChart>
              </ResponsiveContainer>
            </div>
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: 6,
                }}
              >
                <div
                  style={{
                    height: 12,
                    width: 12,
                    background: "#61CEF7",
                    borderRadius: 24,
                  }}
                />
                <span
                  style={{
                    fontSize: 16,
                    fontWeight: "700",
                    fontFamily: "Montserrat",
                    color: "white",
                  }}
                >
                  Received
                </span>
                <div
                  style={{
                    color: "white",
                    marginLeft: 115,
                  }}
                >
                  <span
                    style={{
                      fontFamily: "Montserrat",
                      color: "white",
                      fontWeight: "400",
                      fontSize: 16,
                    }}
                  >
                    {handleSafeParse(lockrMailPreferenceData?.totalEmailReceived ?? '0')}
                  </span>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: 6,
                  marginTop: 10,
                }}
              >
                <div
                  style={{
                    height: 12,
                    width: 12,
                    background: "#FAC748",
                    borderRadius: 24,
                  }}
                />
                <span
                  style={{
                    fontSize: 16,
                    fontWeight: "700",
                    fontFamily: "Montserrat",
                    color: "white",
                  }}
                >
                  Delivered
                </span>
                <div
                  style={{
                    color: "white",
                    marginLeft: 111,
                  }}
                >
                  <span
                    style={{
                      fontFamily: "Montserrat",
                      color: "white",
                      fontWeight: "400",
                      fontSize: 16,
                    }}
                  >
                    {handleSafeParse(lockrMailPreferenceData?.totalDelivered ?? '0')}
                  </span>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: 6,
                  marginTop: 10,
                }}
              >
                <div
                  style={{
                    height: 12,
                    width: 12,
                    background: "#BD94BC",
                    borderRadius: 24,
                  }}
                />
                <span
                  style={{
                    fontSize: 16,
                    fontWeight: "700",
                    fontFamily: "Montserrat",
                    color: "white",
                  }}
                >
                  Opened
                </span>
                <div
                  style={{
                    color: "white",
                    marginLeft: 125,
                  }}
                >
                  <span
                    style={{
                      fontFamily: "Montserrat",
                      color: "white",
                      fontWeight: "400",
                      fontSize: 16,
                    }}
                  >
                    {handleSafeParse(lockrMailPreferenceData?.totalEmailsopened ?? '0')}
                  </span>
                </div>
              </div>
              {renderlockrMailPreferenceCards()}
            </div>
          </> : 
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <span style={{ color: "white" }}>There is no data available for the selected date range.</span>
            </div>
          }
          </div>
        </div>
      </div>
    );
  }

  function renderCharts() {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: 24,
          marginTop: 32,
        }}
      >
        {renderEmailSettingsChart()}
        {renderEmailAnalyticsChart()}
      </div>
    );
  }

  return (
    <div
      style={{
        marginLeft: 90,
        marginRight: 90,
      }}
    >
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
      }}>
        <span
          style={{
            fontFamily: "Montserrat",
            fontWeight: "700",
            fontSize: 20,
            color: "white",
          }}
        >
          lockr
          <span
            style={{
              color: "#61CEF7",
            }}
          >
            Mail
          </span>{" "}
          user preferences
        </span>
        <LockrFromToDatePicker
          endDate={endDate}
          startDate={startDate}
          onChangeEndDate={setEndDate}
          addCustomDatePicker
          onChangeStartDate={setStartDate}
        />
      </div>
      {renderCharts()}
    </div>
  );
}
