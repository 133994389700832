import { Button, Modal } from "antd";
import { useEffect, useState } from "react";
import {
  BASE_URL,
  checkUserCanViewAPIDocs,
  DOCS_URL,
  hasPublisherSeenApiDocs,
  updateUserViewedApiDocs,
} from "../Utils/ApiActions";

export function ViewApiDocsModal({ onCancel }) {
  const [showApiDocsModal, setShowApiDocsModal] = useState(false);

  useEffect(() => {
    fetchUserCanSeeApiDocsModal();
  }, []);

  async function fetchPublisherCanSeeApiDocsModal() {
    try {
      const response = await hasPublisherSeenApiDocs();
      if (response.success) {
        setShowApiDocsModal(!response.data);
      }
    } catch (error) {
      console.log("Unable to get the publisher check to see if the ");
      console.log(error);
    }
  }

  async function fetchUserCanSeeApiDocsModal() {
    try {
      const response = await checkUserCanViewAPIDocs();
      if (response.success) {
        setShowApiDocsModal(response.data);
      }else {
        fetchPublisherCanSeeApiDocsModal();
      }
    } catch (error) {
      console.log(
        `Unable to get the data from the api to check if the user can see the api docs.`
      );
      console.log(error);
    }
  }

  async function updateUserSawApiDocsModal() {
    try {
      window.open(DOCS_URL);
      const response = await updateUserViewedApiDocs();
      if (response.success) {
        setShowApiDocsModal(false);
      }
    } catch (error) {
      console.log(error);
      console.log("Unable to update the user viewed the docs.");
    }
  }

  return (
    <Modal
      visible={showApiDocsModal}
      centered
      width={776}
      footer={null}
      closable={false}
      destroyOnClose
      bodyStyle={{
        width: 776,
        maxHeight: 810,
        backgroundColor: "#353738",
        paddingTop: 48,
        paddingBottom: 60,
        paddingRight: 0,
        overflowX: "hidden",
        position: "relative",
        paddingLeft: 0,
      }}
    >
      <img
        onClick={() => {
          setShowApiDocsModal(false);
        }}
        alt={"Close"}
        src={require("../assets/modal-close.png")}
        className="lockr-api-docs-cross-x-button"
      />
      <div style={{ paddingRight: 60, paddingLeft: 60 }}>
        <div style={{ display: "flex", flexDirection: "column", height: 120 }}>
          <label className="lockr-api-docs-modal-title">{`Welcome to`}</label>
          <label
            style={{ marginTop: -20 }}
            className="lockr-api-docs-modal-title"
          >
            AIM!
          </label>
        </div>
        <div
          style={{ marginTop: 24, display: "flex", flexDirection: "column" }}
        >
          <label className="lockr-api-docs-modal-subtitle">
            Check out our tips below to get started and make the most of your
            account:
          </label>
          {/* <label style={{ marginTop: 24, }} className="lockr-api-docs-modal-subtitle">To get started validating email addresses in real time, please <label onClick={()=>{
                        updateUserSawApiDocsModal();
                    }} className="lockr-api-docs-link">integrate the lockr API and SSO solution.</label></label> */}
        </div>
        <div
          style={{
            flexDirection: "row",
            display: "flex",
            marginTop: 40,
            alignItems: "center",
          }}
        >
          <div>
            <img
              alt="n_1"
              src={
                "https://lockrmail-assets.s3.us-west-2.amazonaws.com/edm/mobile_onboarding_n_1.png"
              }
            />
          </div>
          <img
            alt="asset_1"
            style={{
              width: 220,
              height: 146,
              marginLeft: 24,
            }}
            src={
              "https://lockrmail-assets.s3.us-west-2.amazonaws.com/uploads/welcome_email_new_app.png"
            }
          />
          <div
            style={{
              maxWidth: 200,
              marginLeft: 32,
            }}
          >
            <span
              style={{
                color: "white",
                fontSize: 14,
                fontWeight: "400",
                lineHeight: "24px",
                fontFamily: "Montserrat",
              }}
            >
              Set up your application under{" "}
              <label
                style={{
                  fontWeight: "700",
                }}
              >
                API & Services
              </label>{" "}
              and review our{" "}
              <a
                style={{
                  fontWeight: "700",
                  color: "#61CEF7",
                  fontSize: 16,
                }}
                href={DOCS_URL}
              >
                API Docs
              </a>{" "}
              for integration details.{" "}
            </span>
          </div>
        </div>
        <div
          style={{
            flexDirection: "row",
            display: "flex",
            marginTop: 40,
            alignItems: "center",
          }}
        >
          <div>
            <img
              alt="n_2"
              src={
                "https://lockrmail-assets.s3.us-west-2.amazonaws.com/edm/mobile_onboarding_n_2.png"
              }
            />
          </div>
          <img
            alt="asset_1"
            style={{
              width: 220,
              height: 146,
              marginLeft: 24,
            }}
            src={
              "https://lockrmail-assets.s3.us-west-2.amazonaws.com/uploads/welcome_email_test.png"
            }
          />
          <div
            style={{
              maxWidth: 200,
              marginLeft: 32,
            }}
          >
            <span
              style={{
                color: "white",
                fontSize: 14,
                fontWeight: "400",
                lineHeight: "24px",
                fontFamily: "Montserrat",
              }}
            >
              Seamlessly test and integrate alternative identity providers.
            </span>
          </div>
        </div>
        <div
          style={{
            flexDirection: "row",
            display: "flex",
            marginTop: 40,
            alignItems: "center",
          }}
        >
          <div>
            <img
              alt="n_3"
              src={
                "https://lockrmail-assets.s3.us-west-2.amazonaws.com/edm/mobile_onboarding_n_3.png"
              }
            />
          </div>
          <img
            alt="asset_1"
            style={{
              width: 220,
              height: 146,
              marginLeft: 24,
            }}
            src={
              "https://lockrmail-assets.s3.us-west-2.amazonaws.com/uploads/welcome_email_signin.png"
            }
          />
          <div
            style={{
              maxWidth: 200,
              marginLeft: 32,
            }}
          >
            <span
              style={{
                color: "white",
                fontSize: 14,
                fontWeight: "400",
                lineHeight: "24px",
                fontFamily: "Montserrat",
              }}
            >
              Authenticate users with consented and verified first-party data.
            </span>
          </div>
        </div>
        <div
          style={{
            marginTop: 48,
          }}
        >
          <span
            style={{
              color: "white",
              fontSize: 16,
              fontWeight: "400",
              fontFamily: "Montserrat",
            }}
          >
            Questions?{" "}
            <a
              href="https://loc.kr/contact/"
              style={{
                fontWeight: "700",
                color: "#50E8B1",
              }}
            >
              We're here to help
            </a>
          </span>
        </div>
        <div onClick={()=>{
            setShowApiDocsModal(false);
        }} style = {{
            background: '#50E8B1',
            width: 218,
            height: 60,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 20,
            color: 'black',
            fontSize: 16,
            fontWeight: '700',
            cursor: 'pointer'
        }}>
            View Dashboard
        </div>
        {/* <div style = {{
                    marginTop: 60,
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center'
                }}>
                <Button onClick={updateUserSawApiDocsModal} style = {{
                    background: '#50E8B1',
                    height: 60, 
                    width: 230,
                    border: '0px solid transparent'
                }}>
                    <label className="lockr-api-docs-button-text">View API Docs</label>
                </Button>
                </div> */}
      </div>
    </Modal>
  );
}
