import { Col, message, Progress, Row, Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min'
import FloatLabel from '../../Components/FloatLabel/FloatLabel'
import RegCommonButton from '../../Components/RegCommonButton'
import RegCommonInput from '../../Components/RegCommonInput'
import RegCommonTitle from '../../Components/RegCommonTitle'
import { getVerticalList } from '../../Utils/ApiActions'

const Step3 = (props) => {
    function focusOut() {
        let elements = document.getElementsByTagName("input");
        if (elements.length === 0) {
            return;
        }
        for (let i = 0; i < elements.length; i++) {
            elements[i].classList.remove('lockr-form-control-focus');
        }
    }
    function getFocus() {
        let elements = document.getElementsByTagName("input");

        if (elements.length === 0) {
            return;
        }
        for (let i = 0; i < elements.length; i++) {
            elements[i].classList.add('lockr-form-control-focus');
        }
    }

    const [bName, setBName] = useState('')
    const [vertical, setVertical] = useState('');
    const [website, setWebsite] = useState('')
    const [mobile, setMobile] = useState('')
    const [displayLoading, setDisplayLoading] = useState(false)
    const [error, setError] = useState(false)
    const [errorText, setErrorText] = useState('')
    const [verticleOptions, setVerticleOptions] = useState([]);


    const urlValidation = new RegExp(/(([\w]+:)?\/\/)?(([\d\w]|%[a-fA-f\d]{2,2})+(:([\d\w]|%[a-fA-f\d]{2,2})+)?@)?([\d\w][-\d\w]{0,253}[\d\w]\.)+[\w]{2,63}(:[\d]+)?(\/([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)*(\?(&?([-+_~.\d\w]|%[a-fA-f\d]{2,2})=?)*)?(#([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)?/, 'gmi');

    const _signUpDetails = props?.location?.state?.signUpDetails

    useEffect(() => {
        if (props?.location?.state === undefined || _signUpDetails === undefined) {
            props.history.replace('/console/signup')
        }
        getVerticalList()
            .then(response => {

                setVerticleOptions(response.data)
            })
            .catch(error => {
                console.log(error);
            })

    }, [])





    function checkValidation() {
        let _validate = true
        let _urlRegEx = new RegExp(/(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,}|[a-zA-Z0-9]+\.[^\s]{2,})/gi);
        let _mobileRegEx = new RegExp("[0-9]{9,}");
        if (bName.trim() === '') {
            setError(true)
            setErrorText("Business Name Can't be blank");
            return _validate = false
        }
        if (website.trim() === '' || !_urlRegEx.test(website)) {
            setError(true)
            setErrorText("Invalid URL");
            return _validate = false
        }
        if (mobile.trim() !== '' && !_mobileRegEx.test(mobile)) {
            setError(true)
            setErrorText("Invalid phone number");
            return _validate = false
        }
        return _validate
    }

    const handleNextClick = () => {
        if (checkValidation()) {
            setDisplayLoading(true)
            props.history.push('/console/signup/step4', {
                signUpDetails: {
                    email: _signUpDetails.email,
                    firstName: _signUpDetails.fname,
                    lastName: _signUpDetails.lname,
                    businessName: bName,
                    businessURL: website,
                    businessPhone: mobile,
                    businessVertical: vertical.id
                }
            })
        }
    }

    document.body.classList.add('lockr-bg-img');
    return (
        <Spin spinning={displayLoading}>
            <Row>
                <Progress className='reg-steps' percent={75} showInfo={false} />
            </Row>
            <Row justify='start' className='reg-container'>
                <Row style={{ width: '100%' }}>
                    <Col>
                        <img className="lockr-logo" src={require('../../assets/onboard_lock.42d74f50.png')} alt='lock-img' />
                    </Col>
                </Row>
                <RegCommonTitle title={'Tell us about your business'} />
                <Row className="lockr-join2plus" style={{ marginTop: 48 }}>
                    <Col sm={14} xs={24}>
                        <FloatLabel value={bName} label={'Business name *'}>
                            <RegCommonInput className={error ? 'errorInputText' : null} value={bName}
                                onChange={(event) => {
                                    setBName(event.target.value)
                                    setError(false)
                                }} onFocus={(event) => {
                                    getFocus();
                                }} onBlur={(event) => {
                                    focusOut();
                                }}
                            />
                        </FloatLabel>
                    </Col>

                    <Col className='lockr-website-control' sm={14} xs={24}>
                        <FloatLabel value={website} label={'Website URL *'}>
                            <RegCommonInput captureTab className={error ? 'errorInputText' : null} value={website}
                                onChange={(event) => {
                                    setWebsite(event.target.value)
                                    setError(false)
                                }} onFocus={(event) => {
                                    getFocus();
                                }} onBlur={(event) => {
                                    focusOut();
                                }}
                            />
                        </FloatLabel>
                    </Col>
                    <Col sm={14} xs={24}>
                        <FloatLabel value={vertical.name} label={'Business vertical *'} >
                            <RegCommonInput verticleOptions={verticleOptions} isDropDown className={error ? 'errorInputText' : null} value={vertical.name}
                                onChange={(event) => {

                                    setVertical(event)
                                    setError(false)
                                }} onFocus={(event) => {
                                    getFocus();
                                }} onBlur={(event) => {
                                    focusOut();
                                }}
                            />
                        </FloatLabel>
                    </Col>
                    <Col sm={14} xs={24}>
                        <RegCommonInput isPhoneNumber className={error ? 'errorInputText' : null} type={'number'} value={mobile}
                            onChange={(event) => {
                                setMobile(event)
                                setError(false)
                            }} onFocus={(event) => {
                                getFocus();
                            }} onBlur={(event) => {
                                focusOut();
                            }}
                        />
                    </Col>
                    {error && <Col sm={14} xs={24}>
                        <label className='errorText'>{errorText}</label>
                    </Col>}
                </Row>
                <RegCommonButton disabled={bName === '' || !urlValidation.test(website) || vertical === ""} btntext={'Next'} onClick={handleNextClick} />
            </Row>
        </Spin>
    )
}

export default withRouter(Step3)